import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Grid, Box } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { green, grey, red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Auth from '../utils/Auth';
import SearchBarPlus from "./SearchBarPlus";
import BestellungenTopBar from './BestellungenTopBar';
import MessageComponent from './MessageComponent';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    padding: theme.spacing(0),
  },
}));


function Row(props) {
  const { row, dataChanged } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const handleClickPrintForce = (postid) => {
    console.log(`handleClickPrintForce postid`, postid);

    const data = {
      'order_print_force': 'on',
      // 'order_gedruckt': false
    }
    Auth.axios.put(`/wp-json/kiarest/v1/resorder/${postid}`, data).then(
      res => {
        console.log("PUT order_print_force res", res);
        dataChanged(new Date());
        props.logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log(`PUT order_print_force err`, err);
        props.logError("Fehler.");
      }
    )
  }

  const handleClickDeleteOrder = (postid) => {
    console.log(`handleClickDeleteOrder postid`, postid);

    Auth.axios.delete(`/wp-json/kiarest/v1/resorder/${postid}`).then(
      res => {
        console.log("DELETE ORDER res", res);
        dataChanged(new Date());
      }
    ).catch(
      err => {
        console.log(`DELETE ORDER err`, err);
      }
    )
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> {row.order_bestellnummer}
        </TableCell>
        <TableCell component="th" scope="row">{row.order_vorname} {row.order_nachname}</TableCell>
        <TableCell align="right">{row.order_telefon}</TableCell>
        <TableCell align="right">{row.order_gesamtsumme} €</TableCell>
        <TableCell align="right">{row.bestellzeit}</TableCell>
        <TableCell align="right">{row.order_abholzeit}</TableCell>
        <TableCell align="right">
          {/* <IconButton aria-label="expand row" size="small"> */}
          {row.order_gedruckt == 'on' ? <Icon style={{ color:green[500], top:'8px', position:'relative' }}>check_circle</Icon> : <Icon style={{ color: grey[500], top:'8px', position:'relative'  }}>circle</Icon>}
          {/* </IconButton> */}
          <IconButton aria-label="expand row" size="small" onClick={() => handleClickPrintForce(`${row.id}`)}>
            {row.order_print_force == 'on' ? <Icon style={{ color:grey[500] }}>pending</Icon> : <Icon style={{ color:grey[500] }}>print</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => handleClickDeleteOrder(`${row.id}`)}>
            <Icon style={{ color: red[500] }}>delete</Icon>
          </IconButton>
        </TableCell>
        {/* {row.order_gedruckt == 'on' ? <Icon style={{ color: green[500] }}>check_circle</Icon> : <Icon style={{ color: grey[500] }}>circle</Icon>} */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">Bestelldetails</Typography>
              <Typography variant="p" gutterBottom component="div">{row.order_email}</Typography>
              <Typography variant="p" gutterBottom component="div">ID: {row.id}</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Gericht</TableCell>
                    <TableCell>Anzahl</TableCell>
                    <TableCell align="right">Einzelpreis</TableCell>
                    <TableCell align="right">Gesamtpreis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.order_produkte.map((productRow) => (
                    <TableRow key={productRow.date}>
                      <TableCell component="th" scope="row">{productRow.name}</TableCell>
                      <TableCell>{productRow.qty}</TableCell>
                      <TableCell align="right">{productRow.price} €</TableCell>
                      <TableCell align="right">{productRow.total} €</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default function OrderTableAdvanced() {
  const classes = useStyles();
  const [data, setData] = useState([]);      // Vollständige Daten, die vom Server abgefragt wurden
  const [rows, setRows] = useState(data);    // Gefilterte Daten, durch die SearchBar (Also eine Submenge von data)
  const [updateData, setUpdateData] = useState(new Date());
  const [logMessage, setLogMessage] = useState();
  
  useEffect(() => {
    const interval = setInterval(() => updateTable(), 120000);

    return () => {
      console.log('clearing interval');
      clearInterval(interval);
    };
  })

  function updateTable() {
    setUpdateData(new Date());
  }

  useEffect(() => {
    Auth.axios.get('/wp-json/kiarest/v1/resorder/').then(
      res => {
        console.log("res: ", res);
        setData(res.data);
        setRows(res.data);
      }
    ).catch(
      err => {
        console.log(`err`, err);
      }
    )
  },[updateData]);

  const handleSearchBar  = async (input) => {
    const filteredRows = data.filter((row) => {
      return row.order_nachname.toLowerCase().includes(input.toLowerCase()) || row.order_vorname.toLowerCase().includes(input.toLowerCase());
    });

    setRows(filteredRows);
  }

  const handleCancelSearch = (e) => {
    e.preventDefault();
    console.log(`handleCancelSearch! e.target`, e.target);
  }

  const logSuccess = (content) => {
    setLogMessage({key: `${new Date()}`, msg: `${content}`, severity: 'success'});
  }

  const logError = (content) => {
    setLogMessage({key: `${new Date()}`, msg: `${content}`, severity: 'error'});
  }

  return (
    <React.Fragment>
      <Box mb={0} ml={1} mr={1}>
        <Grid container spacing={1}>
          <Grid container xs={5} alignItems="center">
            <SearchBarPlus onChange={handleSearchBar} onCancelSearch={handleCancelSearch} />
          </Grid>
          <Grid container xs={7} direction="row" justify="flex-end" alignItems="center">
            <IconButton aria-label="expand row" size="small" onClick={() => updateTable()} style={{ marginRight: '8px' }}>
              <Icon style={{ color: grey[500] }}>refresh</Icon>
            </IconButton>
            <BestellungenTopBar />
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">Telefon</TableCell>
              <TableCell align="right">Summe</TableCell>
              <TableCell align="right">Eingang</TableCell>
              <TableCell align="right">Abholung</TableCell>
              <TableCell align="right">Gedruckt</TableCell>
              <TableCell align="right">Löschen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} row={row} logSuccess={logSuccess} logError={logError} dataChanged={setUpdateData} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MessageComponent message={logMessage} />
    </React.Fragment>
  );
}
